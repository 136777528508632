import React from "react";
import "./AboutMe.css";
import experienceIcon from "../../assets/experience.png";
import educationIcon from "../../assets/education.png";

const AboutMe = () => {
  return (
    <>
      <section id="aboutMe">
        <span className="aboutMeTitle">About Me </span>
        <span className="aboutMeDesc">
          Bringing over a year of hands-on experience in web development, I am a
          dedicated professional currently pursuing a BCA Bachelor's Degree,
          currently in the eighth semester. My skill set encompasses diverse web
          development technologies for effective project contributions. I
          completed a 4-month Web Developer internship at Bitmap I.T. Solution
          Pvt. Ltd, focusing on React and Laravel.
        </span>
        <div className="about-containers">
          <div className="details-container">
            <img src={experienceIcon} alt="Experience icon" className="icon" />
            <h3>Experience</h3>
            <p>
              4 months Internship <br />
              at{" "}
              <a href="https://bitmapitsolution.com/" class="experience-link">
                Bitmap I.T. Solution Pvt. Ltd
              </a>
              <br />
              as Web Developer, <br />
              focusing on React and Laravel.
            </p>
          </div>
          <div className="details-container">
            <div className="aboutImgContainer">
              <img src={educationIcon} alt="Education icon" className="icon" />
            </div>
            <h3>Education</h3>
            <p>
              BCA
              <br />
              Seventh Semester
              <br />
              Tribhuvan University
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutMe;
